.signup-form {
  // font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 600px;
  margin: 0 auto;
  padding: 30px 0;
  h2 {
    color: #636363;
    margin: 0 0 15px;
    text-align: center;
  }
  .lead {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }
  form {
    border-radius: 1px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
  }
  
}
