.stepper-container-horizontal {
  // border: 1px solid #FCB402;
  // box-shadow: 0 0 13px #333;
  // border-radius: 3px;

  // margin: 40px auto;
  width: 100%;

  @media screen and (max-width: 900px) {
      width: 100%;
  }
}

.stepper-container-vertical {
  border: 1px solid #FCB402;
  box-shadow: 0 0 13px #333;
  border-radius: 3px;
  margin: 40px auto;
  padding: 30px;
  width: 15%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttons-container {
  text-align: center;

  button {
    margin: 0 2rem;
    width: 100px;
    padding: 4px;
    background-color: #FCB402;
    border: none
  }
}



.stepper-wrapper-horizontal {
  display: flex;
  justify-content: space-between;

  .step-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
  }

  .step-number {
      border-radius: 50%;
      border: 1px solid grey;
      width: 30px;
      height: 30px;
      padding: 3px;
      text-align: center;
      margin-bottom: 1.2rem;
  }

  .divider-line {
      height: 1px;
      background-color: #bdbdbd;
      position: absolute;
      top: calc((2em - 1px) / 2);
      left: calc((50% + 2em) - 8px);
      right: calc((-50% + 2em) - 8px);
  }

  .divider-line-2 {
      width: 296%
  }

  .divider-line-3 {
      width: 125%
  }

  .divider-line-4 {
      width: 70%
  }

  .divider-line-5 {
      width: 60%
  }
}

// .stepper-wrapper-vertical {
//   .step-wrapper {
//       display: flex;
//       align-items: center;
//       margin-bottom: 1.2rem;
//       position: relative;
//   }

//   .step-number {
//       border-radius: 50%;
//       width: 18px;
//       height: 18px;
//       padding: 4.5px 4px 4px;
//       text-align: center;
//       font-size: 95%;
//   }

//   .step-description {
//       margin-left: 1.2rem;
//       padding-bottom: 3px;
//   }

//   .divider-line {
//       height: 17px;
//       width: 1px;
//       background-color: #bdbdbd;
//       position: absolute;
//       top: 100%;
//       left: 6.4%
//   }
// }

.step-number-selected {
  border: 1px solid #bdbdbd;
  color: #fff;
  background-color: orange;
  font-weight: bold;
}

.step-number-disabled {
  border: 1px solid #838383
}

.step-description-active {
  font-weight: bold;
}
