@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;600&display=swap");

.modal-dialog {
  // padding-top: 15%;
}

body {
  font-family: Montserrat;
}

// .btn-group {
//   margin: 10px;
//   button {
//     margin-right: 20px;
//   }
// }

.questionaire {
  .container {
    background-color: #555;
    // opacity: 0.9;
    color: #ddd;
    border-radius: 10px;
    padding: 20px;
    font-family: "Montserrat", sans-serif;
    max-width: 700px;
    min-width: 700px;
  }

  .container > p {
    font-size: 32px;
  }

  .question {
    width: 100%;
  }

  .options {
    position: relative;
    padding-left: 40px;
  }

  #options {
    width: fit-content;
    label {
      display: block;
      margin-bottom: 15px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .options input {
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #555;
    border: 1px solid #ddd;
    border-radius: 50%;
  }

  .options input:checked ~ .checkmark:after {
    display: block;
  }

  .options .checkmark:after {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 300ms ease-in-out 0s;
  }

  .options input[type="radio"]:checked ~ .checkmark {
    background: #21bf73;
    transition: 300ms ease-in-out 0s;
  }

  .options input[type="radio"]:checked ~ .checkmark:after {
    transform: translate(-50%, -50%) scale(1);
  }

  .btn-primary {
    background-color: #555;
    color: #ddd;
    border: 1px solid #ddd;
  }

  .btn-primary:hover {
    background-color: #21bf73;
    border: 1px solid #21bf73;
  }

  .btn-success {
    padding: 5px 25px;
    background-color: #21bf73;
  }

  @media (max-width: 576px) {
    .question {
      width: 100%;
      word-spacing: 2px;
    }
  }
}

.Share-wrapper-container {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Share-wrapper-container__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Share-wrapper-container__share-button {
  cursor: pointer;
}

.Share-wrapper-container__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Share-wrapper-container__custom-icon {
  width: 32px;
  height: 32px;
}

.error-template {
  padding: 20%;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
